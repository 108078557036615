<template>
    <div>
        <SelectMyDocument ref='SelectMyDocument'  @SelectMyDocument='SelectMyDocument'></SelectMyDocument>
    </div>
</template>
<script>
import SelectMyDocument from '../views/onlineEditing/components/SelectMyDocument.vue'
export default {
    components: {
        SelectMyDocument
    },
    data(){
        return {
            type:'file'
        }
    },
    mounted(){
        this.type = window.location.href.substr(window.location.href.indexOf('?type=')+6)
        this.$refs.SelectMyDocument.show(this.type,null,'shareToMe');
    },
    methods:{
        SelectMyDocument(id,suffix){
            if(id == null){
               
                window.parent.postMessage({
                    'close':true,
                },'*')
            }else{
                if(this.type == 'file' && id){
                    window.parent.postMessage({
                        'ids':id,
                        'suffix':suffix
                    },'*')
                }else if(this.type == 'folder'){
                    window.parent.postMessage({
                        'folderId':id,
                    },'*')
                }
            }
           
           
        }
    }
}
</script>